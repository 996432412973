import DesktopHeader from './DesktopHeader'

export default {
  install (App) {
    Object.keys(components).forEach(name => {
      App.component(name, components[name])
    })
  },
}

const components = {
  DesktopHeader,
}
