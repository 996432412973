<template>
  <ion-app>
    <AlertList />
    <router-view :key="viewKey" id="main" />
  </ion-app>
</template>

<script>
import AlertList from './components/common/AlertList'
import { bridgeMutations } from '~gro-modules/Bridge'
import { GrosensComponents } from '~gro-plugins'
import { egroQueries } from '~gro-modules/Egro'

export default {
  components: { AlertList },
  setup () {
    GrosensComponents.loadGrowerType()
  },
  data () {
    return {
      viewKey: 0,
    }
  },
  watch: {
    $connected (connected) {
      if (connected) {
        this.reloadView()
        this.forwardToEgro()
      } else this.attemptToReconnect()
    },
    $growerType () {
      this.applyGrowerTypeStyling()
    },
  },
  methods: {
    async testConnection () {
      if (!await bridgeMutations.ping()) {
        return this.attemptToReconnect()
      }
    },
    async attemptToReconnect () {
      if (!this.$bridge.user.canRefresh() && !this.$bridge.user.canAccess()) {
        await this.$alert('common.Log in again', { headerTag: 'common.Logged out' })
        location.href = '/'
      }
      if (this.$bridge.failover()) {
        this.toast = await this.$toast('common.Reconnecting')
        if (await bridgeMutations.ping()) {
          this.$bridge.persist()
        } else {
          return this.attemptToReconnect()
        }
      } else {
        this.toast = await this.$toast('common.Reconnect failed')
      }
    },
    reloadView () {
      if (this.$route.name.includes('login')) {
        return
      }
      this.viewKey++
      if (this.toast) {
        this.toast.dismiss()
      }
    },
    applyGrowerTypeStyling () {
      if (this.$growerType) {
        document.body.className = `flavor-${this.$growerType.toLowerCase()}`
      }
    },
    async forwardToEgro () {
      if (await egroQueries.isConnected(true)) {
        const egroInfo = await egroQueries.getApiInfo()
        if (egroInfo?.appUrl) {
          const timeout = setTimeout(() => {
            location.href = egroInfo.appUrl
          }, 10 * 1000)

          if (await this.$alert('egro.Redirect', { confirmTag: 'common.Cancel' })) clearTimeout(timeout)
        }
      }
    },
  },
  async mounted () {
    this.applyGrowerTypeStyling()
    if (this.$bridge) {
      await this.testConnection()
      await this.forwardToEgro()
    }
  },
}
</script>
