<template>
  <ion-menu side="end"
            type="overlay"
            menu-id="alert-list"
            content-id="main"
            :swipe-gesture="false"
            @ionDidOpen="onOpen">
    <ion-content class="ion-padding">
      <ion-list>
        <ion-list-header lines="full">
          <ion-label>{{ $t('header.Notifications') }}</ion-label>
        </ion-list-header>

        <ion-item v-for="alert in alertsWithTime"
                  :key="alert.uuid"
                  lines="full"
                  button
                  @click="navigateToConditions(alert)">
          <ion-label>
            <h2>
              <template v-if="alert.isRead">
                <span>{{ alert.name }}</span>
              </template>
              <template v-else>
                <ion-icon name="warning" color="warning" />
                <strong>{{ alert.name }}</strong>
              </template>
            </h2>
            <p class="alert-content">
              {{ alert.content }}
              <br>
              <small>
                {{ alert.receivedAgo }}
              </small>
            </p>
          </ion-label>
        </ion-item>
        <ion-infinite-scroll
          @ionInfinite="loadNewAlerts"
          threshold="100px"
          ref="alertScroller"
        >
          <ion-item v-if="loading">
            <gro-spinner />
          </ion-item>
        </ion-infinite-scroll>
      </ion-list>
    </ion-content>
  </ion-menu>
</template>

<script>
import { alertMutations, alertQueries } from '~gro-modules/Alert'
import moment from 'moment'
import { computed, ref } from 'vue'
import GroSpinner from '../../core-frontend/components/common/GroSpinner'

export default {
  components: { GroSpinner },
  setup () {
    const alerts = ref([])
    const page = ref(0)
    const loading = ref(false)

    const alertsWithTime = computed(() => {
      return alerts.value.map(alert => ({
        ...alert,
        receivedAgo: moment(alert.receivedAt).calendar(),
      }))
    })

    return {
      alerts,
      page,
      loading,
      alertsWithTime,
    }
  },
  methods: {
    async onOpen () {
      this.page = 0
      this.alerts = []
      return this.loadNewAlerts()
    },
    async navigateToConditions (alert) {
      if (!alert.device?.group?.uuid) return
      await this.$closeMenu()
      await this.$storage.forget('conditionsView')
      this.$goto('conditions.show', { uuid: alert.device.group.uuid })
    },
    async loadNewAlerts () {
      this.loading = true
      const newAlerts = await alertQueries.getRecentAsync(this.page)
      this.alerts = [
        ...this.alerts,
        ...newAlerts,
      ]
      this.page++
      this.loading = false
      await this.$refs.alertScroller.complete()
      const unreadAlerts = newAlerts.filter(alert => !alert.isRead)
        .map(alert => alert.uuid)
      if (unreadAlerts.length) {
        return alertMutations.markAsRead(unreadAlerts)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.alert-content {
  white-space: normal;
}

ion-icon {
  vertical-align: middle;
  padding-right: 5px;
}
</style>
