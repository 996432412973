<template>
  <ion-button @click="openModal" :class="{alerted: hasNewExports}">
    <ion-icon name="download" />
  </ion-button>
</template>

<script>
import { exportQueries } from '~gro-modules/Export'
import ExportList from './ExportList'
import { showInModal, toast } from '~gro-plugins/dialogsMixin'
import { watch } from 'vue'
import { Translator } from '~gro-plugins'
import { hasNewExports } from '~root/modules/ExportsState'

export default {
  setup () {
    const { exports, refetch } = exportQueries.getAll()

    watch(hasNewExports, (hasExports) => {
      if (!hasExports) {
        return
      }
      toast('conditions.export.Download ready', null, [{
        text: Translator.translate('conditions.export.Show'),
        handler: openModal,
      }, {
        icon: 'close',
        role: 'cancel',
      }])
    })

    const openModal = async () => {
      await refetch()
      await showInModal(ExportList, {
        getExports: () => {
          return exports
        },
      })
    }

    return {
      exports,
      openModal,
      hasNewExports,
    }
  },
}
</script>

<style lang="scss" scoped>
.alerted {
  position: relative;

  &:after {
    bottom: 0;
    right: 0;
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: var(--ion-color-danger);
  }
}
</style>
<style lang="scss">
ion-toast::part(button) {
  margin: 0 4px;
  color: var(--ion-color-cto-contrast);
}
</style>
