<template>
  <ion-page>
    <modal-header :title="$t('header.Exports')" />
    <ion-content class="ion-no-padding full-heigth">
      <ion-list>
        <ion-item v-for="item in exports"
                  :key="item.uuid"
                  lines="full"
                  :class="newDownloads?.includes(item.uuid) ? 'new-downloadable' : ''"
                  :button="item.status === exportStatus.DONE"
                  @click="downloadExport(item)">
          <ion-label>
            <h2>
              {{ item.name }}
              <ion-badge class="export-status-icon"
                         v-if="item.status !== exportStatus.DONE"
                         :color="item.status === exportStatus.FAILED ? 'warning' : 'primary'">
                <ion-icon v-if="item.status === exportStatus.FAILED" name="warning" />
                {{ $t(`exports.status.${item.status}`) }}
              </ion-badge>
            </h2>
            <p class="export-content">
              <small v-if="item.status !== exportStatus.DONE">
                {{ $t('exports.Created at') }}: {{ item.createdAt }}
              </small>
              <small v-else>
                {{ $t('exports.Available until') }}: {{ item.removeFileAt }}
              </small>
            </p>
          </ion-label>
        </ion-item>
      </ion-list>
    </ion-content>
  </ion-page>
</template>

<script>
import { exportStatus } from '~gro-modules/Export'
import { newExports } from '~root/modules/ExportsState'

export default {
  props: {
    getExports: {
      type: Function,
    },
  },
  setup (props) {
    const exports = props.getExports()
    const newDownloads = [...newExports.value]
    newExports.value = []
    return {
      exports,
      newDownloads,
    }
  },
  data: () => ({
    exportStatus,
  }),
  methods: {
    downloadExport (item) {
      if (item.status !== exportStatus.DONE) return
      const link = document.createElement('a')
      link.href = this.$bridge.getHost().resolve(`api/export/${item.uuid}`)
      link.download = true
      link.style = 'visibility:hidden'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
  },
}
</script>

<style lang="scss" scoped>
.export-content {
  white-space: normal;
}

.export-status-icon {
  float: right;
}

ion-item {
  &.new-downloadable {
    font-weight: bold;

    &::part(native) {
      background-color: #f6f8fc;
    }
  }
}
</style>
